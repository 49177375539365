import React, { useRef } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { navigate } from "@reach/router"

import { getCxFromStyles } from "../helpers"

import {
  Header,
  Lead,
  RateDescription,
  Details,
  Partner,
  Contact,
  Footer,
  CookiesBar,
} from "../components"

import * as styles from "./styles.scss"

const Home = () => {
  const contactRef = useRef()
  const cx = getCxFromStyles(styles)

  const goToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const signIn = () => {
    navigate(`${process.env.GATSBY_APP_URL}/register`)
  }

  return (
    <div className={cx("root")}>
      <section className={cx("section", "header")}>
        <Header goToContact={goToContact} signIn={signIn} />
      </section>
      <div className={cx("square")} />
      <section className={cx("section", "lead")}>
        <Lead goToContact={goToContact} />
      </section>
      <section className={cx("section", "did-you-know")}>
        <RateDescription />
      </section>
      <section className={cx("section", "details")}>
        <Details goToContact={goToContact} />
      </section>
      <section className={cx("section", "benefits")}>
        <Partner />
      </section>
      <section className={cx("section", "contact")}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
        >
          <Contact ref={contactRef} />
        </GoogleReCaptchaProvider>
      </section>
      <section className={cx("section", "footer")}>
        <Footer />
      </section>
      <CookiesBar />
    </div>
  )
}

export default Home
